import React from 'react';

import Layout from '../components/Layout';
import SEO from '../components/Seo';
import * as Page from '../ui-components/page';
import * as Article from '../ui-components/articles';
import * as Form from '../ui-components/form';

const SignupPage = () => (
  <Layout>
    <SEO
      title="Sign-Up for a NoStack Account"
      keywords={[`NoStack`, `no-stack`, `gatsby`, `javascript`, `react`]}
    />

    <Page.PageHeader>
      <Article.ArticleTitle>Create a NoStack Account</Article.ArticleTitle>

      <Article.ArticleSubtitle>
        <p>
          Do you already have a <strong>NoStack</strong> license?
        </p>
      </Article.ArticleSubtitle>
    </Page.PageHeader>

    <Form.FormChoice>
      <Form.FormChoiceItem>
        <Form.FormChoicePrimaryButton to="/new-stack">
          Yes. Create a Stack.
        </Form.FormChoicePrimaryButton>
      </Form.FormChoiceItem>

      <Form.FormChoiceItem>
        <Form.FormChoiceSecondaryButton to="/new-license">
          No. Create a License.
        </Form.FormChoiceSecondaryButton>
      </Form.FormChoiceItem>
    </Form.FormChoice>
  </Layout>
);

export default SignupPage;
